import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "./CartContext";
import { CartItem } from "./CartItem";
import { StandardForm } from "./StandardForm";
import { cartTotalQuantity } from "../utils/cartHelper";
import HoverableButton from "./HoverableButton";

export const Cart = () => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cart, setCart] = useContext(CartContext);
  // @ts-ignore
  const totalQuantity = cartTotalQuantity(cart);
  // @ts-ignore
  // const totalPrice = cartTotalPrice(cart);
  const headersStyle = {
    paddingLeft: "2rem",
    marginTop: "4rem",
    fontFamily: "Oswald",
    fontSize: "1.2rem",
    letterSpacing: "3px"
  };

  return (
    <div>
      <div
        className="container-fluid">
        {totalQuantity > 0 ?
          <div className="row">
            <div className="col-xl-6 col-lg">
              <div className="row text-start">
                <h3 style={headersStyle}>TU CARRITO</h3>
              </div>
              {cart.map((item: any) => (
                <CartItem item={item} key={item.id} isTotalAmount={false} />
              ))}
              <CartItem item={{ title: `TOTAL: ${totalQuantity}${totalQuantity > 1 ? " Unidades" : " Unidad"}` }} isTotalAmount={true} />
            </div>
            <div className="col-xl-6 col-lg">
              <div className="row text-start">
                <h3
                  style={headersStyle}
                >INFORMACIÓN DE PEDIDO</h3>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <StandardForm usesEmailTemplate={true} sendButtonInitialCaption="CONFIRMAR NOTA DE PEDIDO" formClassName="col-12" outerDivClassName="row" />
              </div>
            </div>
          </div>
          :
          <div className="d-flex align-items-center" style={{ height: "50vh" }}>
            <div className="container">
              <h3>EL CARRITO ESTÁ VACÍO</h3>
              <p style={{ fontSize: "1.5rem" }}>Chequea nuestros productos</p>
              <div className="row">
                <div className="col-4 offset-4">
                  <NavLink
                    className="nav-link"
                    exact
                    to={`/`}
                  >
                    <HoverableButton
                      style={{
                        padding: 0,
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "32px"
                      }} caption="VER" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <br />
      {/* <span>Total price: {totalPrice}</span> */}
    </div>
  );
};