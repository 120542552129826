import React from "react";
import * as Constants from "../utils/constants";

class PageUnderConstruction extends React.Component<{}, {}> {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div
        className="page-wrap d-flex flex-row align-items-center"
        style={{ minHeight: "80vh" }} // TODO: avoid hardcoding heights
      >
        <div
          className="container"
          style={{
            width: "100%",
            marginTop: Constants.topMessageHeight, // To avoid overlapping top messages bar with images carousel
            zIndex: 5,
          }}
        >
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div>Página en construcción :)</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageUnderConstruction;
