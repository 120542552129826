import React from "react";
import * as Constants from "../utils/constants";
import breakpointsHelper from "../utils/breakpointsHelper";

class WhatsAppFloatingLogo extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 30,
          zIndex: 1000,
        }}
      >
        <a
          href={Constants.whatsAppLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Constants.waFloatingLogo}
            alt="WhatsApp logo"
            style={{
              height: breakpointsHelper(window.innerWidth, [
                60,
                70,
                80,
                100,
                110,
              ]),
            }}
          />
        </a>
      </div>
    );
  }
}

export default WhatsAppFloatingLogo;
