import React from "react";
import { Row, Col } from "antd";

export const BlankSideColumns = (props: any) => {

  return (
    <Row>
      {/* Columns with dynamic width for large screen */}
      <Col md={{ span: 24, offset: 0 }} xxl={{ span: 20, offset: 2 }}>
        {props.childComponent}
      </Col>
    </Row>
  );
};