import React from "react";
import axios from "axios";
import MediaQuery from "react-responsive";
import LargeImagesCarousel from "./LargeImagesCarousel";
import MessagesGrid from "./MessagesGrid";
import IconsGrid from "./IconsGrid";
import HighlightedProducts from "./HighlightedProducts";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";
import * as Constants from "../utils/constants";

class Home extends React.Component<
  { assetsFinishedLoading: any, categories: { image: string, name: string }[] },
  { items: any; isLoading: boolean; error: any; allCarouselsLoaded: boolean }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      error: null,
      allCarouselsLoaded: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view

    axios
      .get(Constants.spreadsheetSharedUrl)
      .then((res: any) => {
        this.setState({ items: res.data, isLoading: false });
      })
      .catch((err: any) => {
        this.setState({ error: err });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Initial value to count loaded carousels:
  amountOfCarouselsLoaded = 0;

  carouselLoaded = () => {
    this.amountOfCarouselsLoaded += 1;
    if (this.amountOfCarouselsLoaded === this.props.categories.length) {
      this.setState({
        allCarouselsLoaded: true,
      });
      this.props.assetsFinishedLoading();
    }
  }

  render() {
    const { items } = this.state;

    return (
      <div>
        <WhatsAppFloatingLogo />
        <MediaQuery maxWidth={700}>
          <LargeImagesCarousel
            carouselImages={Constants.mobileCarouselImages}
          />
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <LargeImagesCarousel carouselImages={Constants.carouselImages} />
        </MediaQuery>
        <MessagesGrid messages={Constants.messagesForHomepageGrid} />
        <IconsGrid icons={Constants.workflowIcons} />
        <div className="parallax"></div>
        {/* Map categories from constants array into several carousels */}
        {this.props.categories.map((category: any, index: number) => (
          <HighlightedProducts
            // Filters items based on category name:
            items={items.filter((item: any) => item.category === category.name)}
            backgroundColor="white"
            categoryName={category.name.toUpperCase()}
            categoryUrl={`/${category.name}`}
            carouselLoaded={this.carouselLoaded}
            key={index}
            compactMode
            linkEnabled={true}
            isSelector={false}
          />
        ))}
      </div>
    );
  }
}

export default Home;
