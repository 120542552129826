import React from "react";
import axios from "axios";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "../Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/About";
import ProductsHome from "../ProductsHome";
import Contact from "../../components/Contact";
import SocialNetworks from "../../components/SocialNetworks";
import SingleProduct from "../../components/SingleProduct";
import SubProductsHome from "../../components/SubProductsHome";
import { Cart } from "../../components/Cart";
import { Spin } from "antd";
import Footer from "../Footer";
import { BlankSideColumns } from "../../components/BlankSideColumns";
import * as Constants from "../../utils/constants";

// Object required to dynamically map Routes:
// TODO: create dynamically from sections array
const componentRegistry: any = {
  Home: Home,
  About: About,
  ProductsHome: ProductsHome,
  Contact: Contact,
  SocialNetworks: SocialNetworks,
};

class App extends React.Component<
  {},
  { items: any; isLoading: boolean; assetsFinishedLoading: boolean; error: any, nonEmptyCategories: { image: string, name: string }[] }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      isLoading: false,
      assetsFinishedLoading: false, // Set to true manually for debugging
      error: null,
      nonEmptyCategories: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    // Workaround to avoid blocking direct load from other sections. It just overrides the assetsFinishedLoading value if the app is not being loaded from the homepage
    // TODO: define a more general approach
    const path = window.location.pathname;
    if (path !== "/") {
      this.assetsFinishedLoading();
    }

    // TODO: run tests to use "step" config in order to use pagination to retrieve items in batches. Lazy loading? Research

    axios
      .get(Constants.spreadsheetSharedUrl)
      .then((res: any) => {
        // Removed to show out of stock items explicitly. TODO: add as feature flag
        // Filters products out of stock:
        // const availableItems: any = res.data.filter((product: any) => product.stock.toUpperCase() === Constants.flagForProductsInStock);
        // Filters empty categories:
        const nonEmptyCategoriesList: any[] = Array.from(new Set(res.data.map((item: any) => item.category))).filter((el) => {
          return el !== "";
        });
        const nonEmptyCategories: { image: string, name: string }[] = Constants.productCategories.filter((category) => { return nonEmptyCategoriesList.indexOf(category.name) !== -1 });
        this.setState({ items: res.data, isLoading: false, nonEmptyCategories });
      })
      .catch((err: any) => {
        this.setState({ error: err });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  assetsFinishedLoading = () => {
    this.setState({
      assetsFinishedLoading: true,
    });
  }

  render() {
    const appBody = (visibility: string) => (

      <Router basename="/">
        <div className="App" style={{ display: visibility }}>
          <Header />
          <Switch>
            {/* Empty route for home */}
            <Route exact path="/">
              <Home assetsFinishedLoading={this.assetsFinishedLoading} categories={this.state.nonEmptyCategories} />
            </Route>
            <Route exact path={Constants.cartLink}>
              <Cart />
            </Route>
            <Route exact path="/nuestros_productos/">
              <ProductsHome products={this.state.nonEmptyCategories} />
            </Route>
            {/* Defines sections dynamically based on sections array */}
            {Constants.sections.map((section: any, key: any) => (
              <Route
                exact
                path={`/${section.path}`}
                key={key}
                component={componentRegistry[section.componentName]}
              ></Route>
            ))}
            {/* Defines dynamic routes for each product based on sheet contents */}
            {this.state.items.map((item: any, key: any) => (
              <Route
                exact
                path={`/nuestros_productos/${item.category}/${item.title}_${item.id}_${item.sku}`}
                key={key}
                render={(props) => (
                  <SingleProduct {...props} itemProps={item} />
                )}
              ></Route>
            ))}
            {/* Defines dynamic routes for each product category based on non empty categories */}
            {this.state.nonEmptyCategories
              .map((category: any, key: any) => (
                <Route
                  exact
                  path={`/nuestros_productos/${category.name}`}
                  key={key}
                  render={(props) => (
                    <SubProductsHome
                      {...props}
                      categories={this.state.nonEmptyCategories}
                      productCategory={category.name}
                      categoryName="categoryName"
                      items={this.state.items.filter(
                        (item: any) => item.category === category.name
                      )}
                    />
                  )}
                ></Route>
              ))}
          </Switch>
          <Footer />
        </div>
      </Router>
    );

    const { assetsFinishedLoading, error } = this.state;

    if (error) {
      return <p>{JSON.stringify(error.message)}</p>;
    }

    const spinnerImage = (
      <img
        className="spinnerImage"
        style={{ fontSize: 300 }}
        src={Constants.spinnerImage}
        alt="spinnerImage"
      />
    );
    return (
      // Set overflow: hidden to prevent horizontal movement if an element is overflowing to the right:
      <div style={{ overflow: "hidden" }}>
        {/* Classes to keep element centered: */}
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Spin
            className="spinnerContainer"
            style={{
              display: assetsFinishedLoading === true ? "none" : "block",
            }}
            size="large"
            indicator={spinnerImage}
          />
        </div>
        {/* Return body created with dynamic sections, if there aren't any errors and it finished loading */}
        {/* Columns with dynamic width for large screen */}
        <BlankSideColumns childComponent={appBody(assetsFinishedLoading === true ? "block" : "none")}></BlankSideColumns>
      </div>
    );
  }
}

export default App;
