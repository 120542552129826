import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import breakpointsHelper from "../utils/breakpointsHelper";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";
import HighlightedProducts from "./HighlightedProducts";

const SubproductsHome = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allCarouselsLoaded, setAllCarouselsLoaded] = useState(false);
  const [activeSubcategory, setActiveSubcategory] = useState("");
  // Initial value to count loaded carousels:
  let amountOfCarouselsLoaded = 0;

  const handleActiveSubcategory = (activeSubcategory: string) => {
    setActiveSubcategory(activeSubcategory);
  };

  const carouselLoaded = () => {
    amountOfCarouselsLoaded += 1;
    if (amountOfCarouselsLoaded === props.categories.length) {
      setAllCarouselsLoaded(true);
      props.assetsFinishedLoading();
    }
  };
  const sideMargin: string = breakpointsHelper(window.innerWidth, [
    "0",
    "0",
    "0.5rem",
    "0.5rem",
    "0.5rem",
  ]);

  return (
    <div>
      <div>
        <WhatsAppFloatingLogo />
        <div
          className="container-fluid"
          style={{
            padding: breakpointsHelper(window.innerWidth, [
              "",
              "1rem",
              "2rem",
              "3rem",
              "4rem",
            ]),
          }}
        >
          {/* Breadcrumbs: */}
          <div
            className="col-lg-6 col-sm-12"
            style={{
              textAlign: "left",
              letterSpacing: "3px",
              marginTop: breakpointsHelper(window.innerWidth, [
                "2rem",
                "2rem",
                "0",
                "0",
                "0",
              ]),
            }}
          >
            <span>
              <NavLink
                to="/nuestros_productos"
                style={{
                  color: "black",
                  paddingLeft: 0,
                  paddingBottom: "0.8rem",
                }}
              >
                Nuestros Productos
              </NavLink>
            </span>
            <span> / </span>
            <span>
              <b>{props.productCategory}</b>
            </span>
          </div>
          <h1
            style={{
              fontFamily: "Oswald",
              fontSize: "4.2rem",
              letterSpacing: "3px",
              paddingTop: "2rem",
            }}
          >
            {props.productCategory.toUpperCase()}
          </h1>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <HighlightedProducts
                items={props.items}
                backgroundColor="white"
                categoryUrl={`/${props.categoryUrl}`}
                carouselLoaded={carouselLoaded}
                compactMode={false}
                linkEnabled={false}
                handleActiveCategory={handleActiveSubcategory}
                isSelector={true}
              />
            </div>
          </div>
          <div className="row">
            {props.items.filter((item: any) => (item.subcategory === activeSubcategory)).map((item: any, key: any) => (
              <NavLink
                key={key}
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                to={`${window.location.pathname}/${item.title}_${item.id}_${item.sku}`}
                style={{
                  color: "black",
                  padding: "0.1rem",
                  textDecoration: "none",
                }}
              >
                <div
                  className={breakpointsHelper(window.innerWidth, [
                    "container-fluid",
                    "container-fluid",
                    "",
                    "",
                    "",
                  ])}
                  style={{
                    marginBottom: breakpointsHelper(window.innerWidth, [
                      "1rem",
                      "1rem",
                      "1rem",
                      "1rem",
                      "1rem",
                    ]),
                    marginLeft: sideMargin,
                    marginRight: sideMargin,
                    paddingBottom: "2rem",
                    boxShadow: "3px 4px 7px darkgray",
                    overflow: "hidden"
                  }}
                >
                  <div className="row">
                    <div
                      className="container-fluid"
                      style={{
                        height: "400px",
                        width: "400px",
                        paddingTop: "0",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        paddingBottom: "0",
                      }}
                    >
                      <img
                        src={item.image_link_1}
                        alt={item.image_link_1}
                        width={"100%"}
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-center"
                    style={{
                      letterSpacing: "3px",
                      background: "white", opacity: "0.7",
                      height: "5rem"
                    }}
                  >
                    <div
                      className="headerWithSidelines"
                      style={{ padding: "0 3.5rem 0 3.5rem" }}
                    >
                      <hr />
                    </div>
                    <b>{item.title.toUpperCase()}</b>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubproductsHome;
