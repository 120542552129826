import React from "react";
import * as Constants from "../utils/constants";
import { Link } from "react-router-dom";
import breakpointsHelper from "../utils/breakpointsHelper";

const logos = [
  { image: Constants.igLogo, link: Constants.igLink },
  // { image: Constants.fbLogo, link: Constants.fbLink }, // No fb account
  { image: Constants.waLogo, link: Constants.whatsAppLink },
];

class Footer extends React.Component {
  private currentYear = new Date().getFullYear();
  render() {
    return (
      <div>
        <div
          className="container-fluid"
          style={{
            paddingTop: "3em",
            paddingBottom: "3em",
            backgroundColor: "#aaa",
            color: Constants.secondaryColor,
            letterSpacing: "5px",
            textAlign: "left"
          }}
        >
          <div
            className="row"
            style={{
              marginLeft: breakpointsHelper(window.innerWidth, [
                "0",
                "0",
                "7rem",
                "7rem",
                "7rem",
              ]),
            }}
          >
            <div className="col-sm">
              <div
                className="row"
                style={{
                  lineHeight: "84px",
                  fontSize: "1.3rem",
                  fontWeight: 900,
                }}
              >
                MARWAL
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/quienes_somos"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.8rem",
                  }}
                >
                  QUIÉNES SOMOS
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/nuestros_productos"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.8rem",
                  }}
                >
                  NUESTROS PRODUCTOS
                </Link>
              </div>
            </div>
            <div className="col-sm">
              <div
                className="row"
                style={{
                  lineHeight: "84px",
                  fontSize: "1.3rem",
                  fontWeight: 900,
                }}
              >
                CONTACTANOS
              </div>
              <div
                className="row"
              >
                <a
                  className="nav-link"
                  href={`mailto: ${Constants.contactEmail}`}
                  // Styles to remove standard blue color and text underline from anchor tag
                  style={{
                    color: Constants.secondaryColor,
                    textDecoration: "inherit",
                    paddingLeft: 0
                  }}
                >
                  {Constants.contactEmail}
                </a>
                <a
                  className="nav-link"
                  href={`tel: ${Constants.contactTel}`}
                  // Styles to remove standard blue color and text underline from anchor tag
                  style={{
                    color: Constants.secondaryColor,
                    textDecoration: "inherit",
                    paddingLeft: 0
                  }}
                >
                  {Constants.visibleContactTel}
                </a>
              </div>
            </div>
            <div className="col-sm">
              <div
                className="row"
                style={{
                  lineHeight: "84px",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
              >
                SEGUINOS
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(6, 1fr)",
                  gap: "10px",
                  gridAutoRows: "minmax(50px, auto)"
                }}
              >
                {logos.map((logo: any, key: any) => (
                  <div
                    key={key}
                    style={{
                      justifyContent: "left",
                      zIndex: 2,
                      verticalAlign: "middle",
                      // paddingRight: "0.9rem",
                      // fontSize: "1.6vw",
                      backgroundColor: "transparent",
                    }}
                  >
                    <a
                      href={logo.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ fill: "yellow" }}
                        src={logo.image}
                        alt={logo.image}
                      ></img>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col px-0"></div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{
            textAlign: "left",
            fontSize: "0.8rem",
            fontWeight: "bold",
            paddingTop: "1.4em",
            paddingBottom: "1.4em",
            backgroundColor: "white",
            color: "grey",
            letterSpacing: "3px",
          }}
        >
          <div
            className="row"
            style={{
              marginLeft: breakpointsHelper(window.innerWidth, [
                "0",
                "0",
                "7rem",
                "7rem",
                "7rem",
              ]),
            }}
          >
            <div className="col-sm-12 col-lg-5" style={{ paddingLeft: 0 }}>
              Creado por{" "}
              <a
                href="https://studionanami.com" // Styles to remove standard blue color and text underline from anchor tag
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: Constants.primaryColor,
                  textDecoration: "inherit",
                }}
              >
                STUDIO NANAMI
              </a>
            </div>
            <div className="col-sm-12 col-lg-7" style={{ paddingLeft: 0 }}>
              &copy; {this.currentYear} MARWAL. TODOS LOS DERECHOS RESERVADOS.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
