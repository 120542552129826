import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";

export const cloneItem = (item: any) => {
  return cloneDeep(item);
};

export const getProductIndex = (cart: any, productToCompare: any, propertyKey: string): number => {
  const productIndexInCart: number = cart.findIndex((prod: any) => prod[propertyKey] === productToCompare[propertyKey]);
  return productIndexInCart;
};

export const changeProdQuantity = async (config: { sign: string, currentValue: any, hookToSetVisibleValue: any, bulto: string, prod?: any, cart?: any, hookToUpdateCart?: any, hookToUpdateLocalStorage?: any }) => {
  let updatedValue: number = 0;
  const multiplier: number = config.bulto !== "" ? parseInt(config.bulto) : 1; // Define logic to use multiples of bulk
  if (config.sign === "-" && config.currentValue > multiplier) {
    config.hookToSetVisibleValue(config.currentValue - multiplier);
    updatedValue = config.currentValue - multiplier;
    // Redundant block because it failed for some reason if running after both conditions ¯\_(ツ)_/¯
    if (config.hookToUpdateCart) {
      const clonedCart = cloneItem(config.cart);
      const productIndex = getProductIndex(config.cart, config.prod, "id");
      clonedCart[productIndex].quantity = updatedValue;
      await config.hookToUpdateLocalStorage(clonedCart);
      await config.hookToUpdateCart(clonedCart);
    }
  }
  if (config.sign === "+") {
    config.hookToSetVisibleValue(config.currentValue + multiplier);
    updatedValue = config.currentValue + multiplier;
    if (config.hookToUpdateCart) {
      const clonedCart = cloneItem(config.cart);
      const productIndex = getProductIndex(config.cart, config.prod, "id");
      clonedCart[productIndex].quantity = updatedValue;
      await config.hookToUpdateLocalStorage(clonedCart);
      await config.hookToUpdateCart(clonedCart);
    }
  }
};
export const cartTotalQuantity = (cart: any): number => {
  const cartTotalQuantity = cart.reduce((accumulator: number, currentVal: any) => accumulator + currentVal.quantity, 0);
  return cartTotalQuantity;
};

// export const cartTotalPrice = (cart: any): number => {
//   const cartTotalPrice = cart.reduce((acc: number, curr: any) => acc + curr.price, 0);
//   return cartTotalPrice;
// };

const deleteProduct = (cart: any, product: any) => {
  const productIndex: number = getProductIndex(cart, product, "id");
  if (productIndex !== -1) {
    cart.splice(productIndex, 1);
  }
  return cart;
};

export const deleteProductAndUpdateCart = async (cart: any, item: any, hookToUpdateCart: any, hookToUpdateLocalStorage: any) => {
  const clonedCart = cloneItem(cart);
  const updatedCart = deleteProduct(clonedCart, item);
  await hookToUpdateLocalStorage(updatedCart); // Await to prevent race condition when removing items from cart
  await hookToUpdateCart(updatedCart);
};

const getLocalStorageValue = (key: string, defaultValue: string) => {
  // getting stored value:
  const saved: string | null = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : "";
  return initial || defaultValue;
};

export const useLocalStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });
  useEffect(() => {
    // storing input name:
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};