import React from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { Button } from "antd";
import PureReactCarousel from "./PureReactCarousel";
import { HeaderWithSidelines } from "./HeaderWithSidelines";

interface HighlightedProductsProps {
  items: any;
  backgroundColor: string;
  categoryName?: string;
  categoryUrl: string;
  carouselLoaded: any;
  compactMode: boolean;
  linkEnabled: boolean;
  onClick?: any;
  handleActiveCategory?: any;
  isSelector?: boolean;
}

interface HighlightedProductsState {
  hover: boolean;
  active: boolean;
  focus: boolean;
  allImagesLoaded: boolean;
}

class HighlightedProducts extends React.Component<
  HighlightedProductsProps,
  HighlightedProductsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hover: false,
      active: false,
      focus: false,
      allImagesLoaded: false,
    };
  }

  // Helper functions to toggle button states and apply inline css styles accordingly:
  getInitialState = () => {
    return { hover: false, active: false, focus: false };
  }
  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  }
  toggleActive = () => {
    this.setState({ active: !this.state.active });
  }

  toggleFocus = () => {
    this.setState({ focus: !this.state.focus });
  }

  // Initial value for amount of loaded images in carousel:
  loadedImages = 0;

  imageIsLoaded = () => {
    this.loadedImages += 1;
    if (this.loadedImages === this.props.items.length) {
      this.setState({
        allImagesLoaded: true,
      });
      this.props.carouselLoaded();
    }
  }

  render() {
    let buttonStyle;
    const basicButtonStyle = {
      color: "white",
      width: "10rem",
      fontSize: "1rem",
      border: "2px solid black",
      borderRadius: "32px",
      fontWeight: 700,
      padding: 0
    };
    if (this.state.hover) {
      // TODO: avoid hardcoding colors
      buttonStyle = {
        ...basicButtonStyle,
        backgroundColor: "#AA9E9E",
      };
    } else {
      buttonStyle = {
        ...basicButtonStyle,
        backgroundColor: "black",
      };
    }

    return (
      <div>
        <MediaQuery maxWidth={700}>
          <div className="cardsBackground" id="Productos"></div>
          <div
            className="container-fluid"
            style={{
              backgroundColor: this.props.backgroundColor,
              paddingTop: "2rem",
              paddingBottom: "1rem",
            }}
          >
            <div className="row">
              <div
                className="col-12 d-flex justify-content-center text-center"
                style={{
                  fontFamily: "Oswald",
                  fontSize: "2.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  lineHeight: 1,
                }}
              >
                {this.props.categoryName ? (
                  <HeaderWithSidelines
                    headerText={this.props.categoryName}
                    fontSize="2.2rem"
                  />
                ) : null}
              </div>
              <div className="col-12">
                <PureReactCarousel
                  items={this.props.items}
                  imageIsLoaded={this.imageIsLoaded}
                  visible={true}
                  buttonWidth={16}
                  buttonFontSize={0.7}
                  linkEnabled={this.props.linkEnabled}
                  onClick={this.props.onClick ? this.props.onClick : null}
                  compactMode={this.props.compactMode}
                  handleActiveCategory={this.props.handleActiveCategory}
                  isSelector={this.props.isSelector}
                />
              </div>
            </div>
            {this.props.compactMode ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center text-center"></div>
                <div className="col-12">
                  <Link to={`/nuestros_productos${this.props.categoryUrl}`}>
                    <Button
                      className="container"
                      style={buttonStyle}
                      onMouseOver={this.toggleHover}
                      onMouseOut={this.toggleHover}
                      onMouseUp={this.toggleActive}
                      onMouseDown={this.toggleActive}
                      onFocus={this.toggleFocus}
                    >
                      VER TODO ({this.props.items.length})
                    </Button>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <div className="cardsBackground" id="Productos"></div>
          <div
            className="container-fluid"
            style={{
              backgroundColor: this.props.backgroundColor,
              paddingTop: "2rem",
              paddingBottom: "1rem",
            }}
          >
            <div className="row">
              <div className="col-8 offset-2">
                <div>
                  {this.props.categoryName ? (
                    <HeaderWithSidelines
                      headerText={this.props.categoryName}
                      fontSize="4rem"
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <PureReactCarousel
                  items={this.props.items}
                  imageIsLoaded={this.imageIsLoaded}
                  visible={true}
                  buttonWidth={16}
                  buttonFontSize={1}
                  linkEnabled={this.props.linkEnabled}
                  onClick={this.props.onClick ? this.props.onClick : null}
                  compactMode={this.props.compactMode}
                  handleActiveCategory={this.props.handleActiveCategory}
                  isSelector={this.props.isSelector}
                />
              </div>
            </div>
            {this.props.compactMode ? (
              <div
                className="row"
                style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              >
                <div className="col-4 d-flex justify-content-center text-center"></div>
                <div className="col-12">
                  <Link to={`/nuestros_productos${this.props.categoryUrl}`}>
                    <Button
                      className="container"
                      style={buttonStyle}
                      onMouseOver={this.toggleHover}
                      onMouseOut={this.toggleHover}
                      onMouseUp={this.toggleActive}
                      onMouseDown={this.toggleActive}
                      onFocus={this.toggleFocus}
                    >
                      VER TODO ({this.props.items.length})
                    </Button>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default HighlightedProducts;
