import React, { useState } from "react";
import { useLocalStorage } from "../utils/cartHelper";

export const CartContext = React.createContext("");

export const CartProvider = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localStorageCart, setLocalStorageCart] = useLocalStorage("cart", "");
  const initialCartValue = localStorageCart ? localStorageCart : [];
  const [cart, setCart] = useState(initialCartValue);

  return (
    // @ts-ignore
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  );
};