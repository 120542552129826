import React from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";

class ProductsHome extends React.Component<
  { products: { image: string, name: string }[] },
  { isLoading: boolean; error: any }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        <WhatsAppFloatingLogo />
        <MediaQuery maxWidth={700}>
          <div className="cardsBackground" id="Productos"></div>
          <div
            className="container-fluid"
            style={{
              paddingTop: "2rem",
              paddingBottom: "1rem",
            }}
          >
            {this.props.products.map((category: any, index: number) => (
              <Link to={`/nuestros_productos/${category.name}`} style={{
                color: "black",
                textDecoration: "inherit"
              }}
                key={index}>
                <div className="row">
                  <div
                    className="col-6 d-flex justify-content-center text-center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      lineHeight: 1,
                      zIndex: 999
                    }}
                  >
                    <img src={category.image} alt={category.name} style={{ height: "90%", boxShadow: "5px 5px 7px darkgray", width: "110%" }} />
                  </div>
                  <div
                    className="col-6 d-flex justify-content-center text-center"
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Oswald",
                        fontSize: "2rem",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "column",
                        lineHeight: 1,
                        height: "80%",
                        width: "100%",
                        backgroundColor: "#fef0f2"
                      }}
                    >
                      {category.name}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            <div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <div className="cardsBackground" id="Productos"></div>
          <div
            className="container-fluid"
            style={{
              paddingTop: "2rem",
              paddingBottom: "1rem",
              backgroundColor: "#fef1f1"
            }}
          >
            <div className="row justify-content-center">
              {this.props.products.map((category: any, index: number) => (
                <div
                  className="col-xs-6 col-sm-4 col-md-4 col-lg-3 d-flex justify-content-center text-center"
                  style={{
                    margin: "1%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    lineHeight: 1,
                    zIndex: 999
                  }}
                  key={index}
                >
                  <Link to={`/nuestros_productos/${category.name}`} style={{
                    color: "black",
                    textDecoration: "inherit",
                    position: "relative"
                  }}>
                    <div className="hoverable-category-layer" style={{ position: "absolute" }}>{category.name.toUpperCase()}</div>
                    <img src={category.image} alt={category.name} style={{ height: "90%", boxShadow: "5px 5px 7px darkgray", width: "110%" }} />
                  </Link>
                </div>
              ))}
            </div>
            <div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default ProductsHome;
