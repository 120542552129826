import * as Constants from "./constants";

interface IEmailProduct {
  title: string;
  sku: string;
  quantity: string;
}
interface IEmailData {
  products: IEmailProduct[];
  phone: string;
  email: string;
  name: string;
  surname: string;
  cuit: string;
  address: string;
  city: string;
  transport: string;
  comments: string;
}

export const emailTemplate = (emailData: IEmailData): string => {

  const requestDate = new Date();
  const parsedDate: string[] = requestDate.toISOString().split("T")[0].split("-");
  const formattedDate: string = `${parsedDate[2]}/${parsedDate[1]}/${parsedDate[0]}`;

  const formattedProducts: string = emailData.products
    .map((product: IEmailProduct) => {
      return `<tr><td>${product.title}</td><td>${product.sku}</td><td>${product.quantity}</td></tr>`;
    }).join("");

  const emailStyles: string = `<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700');

    * {
      font-family: 'Montserrat', sans-serif;
    }

    img {
      height: 2.5rem;
      padding: 2.5rem 0 2.5rem 1rem;
    }

    body {
      padding: 0 10px 0 10px;
      margin-left: 10px;
    }

    .title {
      font-size: 1.5rem;
    }

    .button-div {
      color: white;
      background-color: black;
      text-decoration: "inherit";
      padding: 15px;
      margin-left: -10px;
      border-radius: 999px;
      display: inline-block;
    }

    table {
      border-collapse: collapse;
      table-layout: auto;
    }

    tr {
      border-bottom: 1px solid #ccc;
      line-height: 28px;
    }

    th {
      text-align: left;
    }

    td,
    th {
      padding-right: 50px;
    }
  </style>`;

  const emailHeader: string = `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="margin-left: -20px;">
  <img src="${Constants.emailLogoURL}" alt="Logo"></table> </br>`;

  const emailContent: string = ` </br>
  <h4>PEDIDO ENVIADO ✓</h4>
  </br>
  <p class="title">HOLA <b>${emailData.name.toUpperCase()}</b></p>
  <p>Te informamos que tu pedido fue recibido.</p>
  <p>Muy pronto nos estaremos comunicando para enviar tu
    presupuesto.</p>
    <p><b>¡Gracias por elegirnos!</b></p>
  </br>
  <a target="_blank" href=${Constants.websiteUrl}>
    <div class="button-div">
      HACER UN NUEVO PEDIDO
    </div>
  </a>
  </br></br>
  <p>DETALLE DEL PEDIDO</p>
  <div class="table-container">
    <table>
      <tr>
        <th>PRODUCTO</th>
        <th>CÓDIGO</th>
        <th>CANTIDAD</th>
      </tr>
      ${formattedProducts}
    </table>
    </br>
    <p>PEDIDO REALIZADO EL: ${formattedDate}</p>
    <p>TEL.: ${emailData.phone}</p>
    <p>E-MAIL: ${emailData.email}</p>
    <p>APELLIDO: ${emailData.surname}</p>
    <p>NOMBRE: ${emailData.name}</p>
    <p>CUIT: ${emailData.cuit}</p>
    <p>DIRECCIÓN: ${emailData.address}</p>
    <p>LOCALIDAD/CIUDAD: ${emailData.city}</p>
    <p>TRANSPORTE: ${emailData.transport}</p>
    <p>COMENTARIOS: ${emailData.comments}</p>
  </div>`;

  const emailHtml: string = `<html><head>${emailStyles}</head><body>${emailHeader}${emailContent}</body></html>`;
  return emailHtml;
};