import React from "react";
import "antd/dist/antd.css";
import * as Constants from "../utils/constants";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";
import breakpointsHelper from "../utils/breakpointsHelper";

class About extends React.Component<
  {},
  {
    isLoading: boolean;
    mailSent: any;
    error: any;
    name: string;
    surname: string;
    email: string;
    message: string;
    captchaPassed: boolean;
  }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      mailSent: null,
      error: null,
      name: "",
      surname: "",
      email: "",
      message: "",
      captchaPassed: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div style={{ backgroundColor: "#fef1f1" }}>
        <WhatsAppFloatingLogo />
        <div className="container-fluid" style={{ paddingBottom: "4rem" }}>
          <div className="row justify-content-center">
            <div
              className="col"
              style={{
                height: "66%",
                width: "66%",
                position: "absolute",
                zIndex: -1,
                visibility: breakpointsHelper(window.innerWidth, [
                  "hidden",
                  "hidden",
                  "hidden",
                  "visible",
                  "visible",
                ]),
              }}
            ></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-6">
              <img
                src={Constants.aboutImage}
                alt={Constants.aboutImage}
                width="100%"
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
              ></img>
            </div>
          </div>
          <h1
            style={{
              fontFamily: "Oswald",
              fontSize: "4.2rem",
              letterSpacing: "3px",
              paddingTop: breakpointsHelper(window.innerWidth, [
                "3rem",
                "3rem",
                "4rem",
                "4rem",
                "4rem",
              ]),
            }}
          >
            NUESTRA HISTORIA
          </h1>
          <div
            className="row justify-content-center"
            style={{
              fontSize: breakpointsHelper(window.innerWidth, [
                "1rem",
                "1rem",
                "1.5rem",
                "1.5rem",
                "1.5rem",
              ]),
              textAlign: "left",
            }}
          >
            <div className="col-sm-10 col-lg-6">
              <div style={{ margin: "25px" }}>
                {Constants.aboutText.map((paragraph: string, i: number) => <p key={i}>{paragraph}<br /></p>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
