// TODO: Review large carousel centered images, not working in Firefox

import React from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

interface PureReactCarouselProps {
  carouselImages: any;
}

interface PureReactCarouselState { }

class PureReactCarousel extends React.Component<
  PureReactCarouselProps,
  PureReactCarouselState
> {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#fef0f2",
          // marginTop: "-0.3rem",
          zIndex: 5,
        }}
      >
        <CarouselProvider
          // Reverting proportions (vertical/horizontal) for large carousel when screen size is lower than breakpoint
          naturalSlideWidth={160}
          naturalSlideHeight={90}
          totalSlides={this.props.carouselImages.length}
          visibleSlides={1}
          step={1}
          infinite={true}
          isIntrinsicHeight={true}
          isPlaying={true}
          interval={3000}
        >
          <Slider>
            {this.props.carouselImages.map((image: any, key: any) => (
              <Slide index={key} key={key}>
                <div
                  key={key}
                  style={{
                    display: "flex !important",
                    overflow: "hidden !important",
                    alignItems: "center !important",
                    justifyContent: "center !important",
                    zIndex: 5,
                  }}
                >
                  <img src={image} alt={image} style={{ width: "100%" }} />
                </div>
              </Slide>
            ))}
          </Slider>
          {/* TODO: define dot colors using constants instead of hardcoding values in index.css file */}
          <DotGroup />
        </CarouselProvider>
      </div>
    );
  }
}

export default PureReactCarousel;
