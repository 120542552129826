import React from "react";
import MediaQuery from "react-responsive";

class IconsGrid extends React.Component<{ icons: { image: string, text: string, bgColor: string }[] }> {
  render() {

    const iconsGrid = (iconsHeight: number, fontSize: number) => (<div>
      <div
        className="container-fluid"
        style={{ padding: "0 !important", lineHeight: 1 }}
      >
        <div className="row">
          {this.props.icons.map((icon, key) => (
            <div className="col" style={{ padding: 0 }} key={key}>
              <div
                style={{
                  fontFamily: "Montserrat",
                  width: "100%",
                  textAlign: "left",
                  fontSize: `${fontSize}rem`,
                  backgroundColor: icon.bgColor,
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <img src={icon.image} alt={icon.text} style={{
                  display: "block",
                  margin: "auto",
                  marginBottom: "0.5rem",
                  height: `${iconsHeight}rem`
                }} />
                <div style={{ color: "white", textAlign: "center", padding: "0.2rem" }}>
                  {icon.text}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>);

    return <div>
      <MediaQuery maxWidth={700}>
        {iconsGrid(3, 0.8)}
      </MediaQuery>
      <MediaQuery minWidth={701}>
        {iconsGrid(5, 1.5)}
      </MediaQuery>
    </div>;

  }
}

export default IconsGrid;
