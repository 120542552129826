import React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../utils/constants";
import breakpointsHelper from "../utils/breakpointsHelper";

// TODO: 991px breakpoint comes from Bootstrap convention. Try to unify conventions to prevent errors
const dynamicLogoPosition = breakpointsHelper(window.innerWidth, [
  "order-first",
  "order-first",
  "order-first",
  "",
  "",
]);

const logoHeight = breakpointsHelper(window.innerWidth, [
  "32px",
  "32px",
  "32px",
  "32px",
  "42px",
])

class HeaderLogo extends React.Component {
  render() {
    return (
      <div className={`nav-item ${dynamicLogoPosition} mx-auto my-auto`}>
        {/* The "mx-auto Bootstrap class centers the image" */}
        <Link className="navbar-brand mx-auto" to="/">
          <img
            height={logoHeight}
            src={Constants.headerLogo}
            alt={Constants.headerLogo}
          ></img>
        </Link>
      </div>
    );
  }
}

export default HeaderLogo;
