// TODO: CHECK POSSIBILITY TO UNIFY WITH MANIFEST.JSON?

// TODO: improve workflow to minimize risk of errors/omissions

////////////////////////////////////////////////////////////////////////////
// cPanel credentials for gsheets in https://script.google.com/d/1J7N-EvSdJY4C9FpQE2MNXBAA5XD5j-T2sSjazwn0aOPvtdAgfbCqwT_P/edit?usp=drive_web&folder=0AOKropr3SwijUk9PVA&splash=yes
// Google Apps Script ID: 1J7N-EvSdJY4C9FpQE2MNXBAA5XD5j-T2sSjazwn0aOPvtdAgfbCqwT_P

//  Also remember to:
//  - Share GAS library script with email users
//  - Update Name, Content and Title in index.html file
//  - Update name in package.json and package-lock.json
//  - Include .htaccess file
//  - Create email (e.g. info@asd.com) on hosting platform
//  - Update access_token in handle_mercadopago.php
//  - Update email data in send_email.php
////////////////////////////////////////////////////////////////////////////

// Nanami Studio data
export const studioLink = "https://studionanami.com";
export const studioVisibleLink = "studionanami.com";
export const copyrightText = "© 2021 Studio Nanami - ";


// Accounts data
export const websiteUrl = "https://inoxmarwal.com.ar";
export const emailApiPath = `${websiteUrl}/send_email.php`;
export const igLink = "https://www.instagram.com/marwal_argentina/";
// export const fbLink = "https://www.facebook.com/marwal/"; // No fb account
export const spreadsheetSharedUrl = `${websiteUrl}/data/inoxmarwal.json`;
export const recaptchaPublicKey = "6LfCuG8eAAAAACHoqyJwim93oye3rOAgRYCsvog0";
export const emailLogoURL: string = `${websiteUrl}/MARWAL_LOGOEMAIL.png`;

// Naming convention for products in stock in Google Sheet:
export const flagForProductsInStock: string = "SI";

// Template data
// IMPORTANT: componentName must always be capitalized
export const sections = [
  {
    visibleName: "Inicio",
    componentName: "Start",
    path: "",
  },
  {
    visibleName: "Quiénes Somos",
    componentName: "About",
    path: "quienes_somos",
  },
  {
    visibleName: "Nuestros Productos",
    componentName: "ProductsHome",
    path: "nuestros_productos",
  },
  {
    visibleName: "Contactanos",
    componentName: "Contact",
    path: "contactanos",
  },
];

export const productCategories = [
  {
    name: "Latas",
    image: require("../images/product_categories/LATAS.jpeg").default,
  },
  {
    name: "Bombillas",
    image: require("../images/product_categories/BOMBILLAS.jpeg").default,
  },
  {
    name: "Mates",
    image: require("../images/product_categories/MATE.jpeg").default,
  },
  {
    name: "Acero Inoxidable",
    image: require("../images/product_categories/ACERO.jpeg").default,
  },
  {
    name: "Juegos Materos",
    image: require("../images/product_categories/JUEGO_MATERO.jpeg").default,
  },
  {
    name: "Matero",
    image: require("../images/product_categories/MATERO.jpg").default,
  },
  {
    name: "Importado",
    image: require("../images/product_categories/IMPORTADO.jpeg").default,
  },
  {
    name: "Set Asado",
    image: require("../images/product_categories/SET_ASADO.jpeg").default,
  },
  {
    name: "Regalos Empresariales",
    image: require("../images/product_categories/REGALOS_EMPRESARIALES.jpg").default,
  },
  {
    name: "Festivo",
    image: require("../images/product_categories/FESTIVOS.jpeg").default,
  },
  {
    name: "Botellas",
    image: require("../images/product_categories/VASOS.jpeg").default,
  },
];
export const workflowIcons = [
  { image: require("../images/noun_online shopping_4095444.svg").default, text: "PEDIS", bgColor: "#ca1916" },
  { image: require("../images/noun_task_2145587.svg").default, text: "COTIZAMOS", bgColor: "#f3ae71" },
  { image: require("../images/noun_pay_2963022.svg").default, text: "ABONAS", bgColor: "#74aca9" },
  { image: require("../images/noun_delivery_1938257.svg").default, text: "ENVIAMOS", bgColor: "#b78080" }
];
export const messagesForHomepageGrid = ["¿CÓMO TRABAJAMOS?"];
export const aboutText: string[] = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
];
export const isLogoCentered: boolean = true;
// TODO: define real links and/or think of a better approach
export const cartLink = "/carrito";
export const allProductsLink = "Productos";

// Search bar data
export const showSearchBar: boolean = false;

// WhatsApp data
const whatsAppPhone = "541124831844";
const whatsAppDefaultMessage =
  "Hola! Me gustaría hacerles una consulta sobre sus productos";
export const whatsAppLink = `https://wa.me/${whatsAppPhone}?text=${encodeURIComponent(
  whatsAppDefaultMessage
)}`;

// Email data
export const contactEmail = "comercial@inoxmarwal.com.ar";
export const contactEmailConfirmationMessage =
  "Gracias por contactarnos! Te responderemos a la brevedad";
export const contactTel: string = "541124831844";
export const visibleContactTel: string = "11 2483 1844";

// General design (ALSO REPLACE VARS IN index.css!!)
export const primaryColor = "#aaa";
export const opacity = 1;
export const secondaryColor = "white";
export const highlightsBackgroundColor = "rgba(227,126,140,1)";
export const cartBadgeColor = "red";
export const topMessageHeight = "0px"; // Used to define top message height, and also for header distance from top and for large images carousel distance from top

// Several logos and images
export const carouselImages = [
  require("../images/SLIDES/DESKTOP/DESKTOP_1.png").default,
  require("../images/SLIDES/DESKTOP/DESKTOP_2.png").default,
  require("../images/SLIDES/DESKTOP/DESKTOP_3.png").default,
  require("../images/SLIDES/DESKTOP/DESKTOP_4.png").default,
  require("../images/SLIDES/DESKTOP/DESKTOP_5.png").default,
];

export const mobileCarouselImages = [
  require("../images/SLIDES/MOBILE/MOBILE_01.png").default,
  require("../images/SLIDES/MOBILE/MOBILE_02.png").default,
  require("../images/SLIDES/MOBILE/MOBILE_03.png").default,
  require("../images/SLIDES/MOBILE/MOBILE_04.png").default,
  require("../images/SLIDES/MOBILE/MOBILE_05.png").default,
];

export const headerLogo = require("../images/LOGO_MARWAL.svg").default;
export const igLogo = require("../images/INSTAGRAM_white.svg").default;
export const fbLogo = require("../images/FACEBOOK_white.svg").default;
export const waLogo = require("../images/WHATSAPP_white.svg").default;
export const waFloatingLogo = require("../images/WHATSAPP.svg").default;
export const spinnerImage = require("../images/spinner.svg").default; export const landingParallax = require("../images/Parallax.jpeg").default;
export const aboutImage = require("../images/Nuestra_historia.jpeg").default;
// Also update images for manifest.json

/* PowerShell commands to:
1) Create timestamp
2) Zip all files in folder after npm run build (it's easier to upload files to server this way)
3) Upload zip to server (provides source and destination as cli params)

$timestamp = Get-Date -Format "yyyy_MM_dd_HH_mm_ss"
compress-archive -path 'C:\Users\Bruno\Documents\Websites\marwal\build\*' -Update -destinationpath "C:\Users\Bruno\Desktop\build_$timestamp.zip" -compressionlevel optimal
node 'C:\Users\Bruno\Documents\Websites\Amigurumi\upload_files (local test).js' "C:/Users/Bruno/Desktop/build_$timestamp.zip" "public_html/marwal/"

*/
