import React, { useState, useEffect } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import CarouselArrow from "../images/flecha.svg";
import breakpointsHelper from "../utils/breakpointsHelper";
import textEllipsis from "../utils/textEllipsis";
import HoverableButton from "./HoverableButton";

interface PureReactCarouselProps {
  items: any;
  imageIsLoaded: any;
  visible: boolean;
  buttonWidth: number;
  buttonFontSize: number;
  linkEnabled: boolean;
  compactMode: boolean; // Allows to toggle certain features, like subtitle and greyed-out images
  onClick?: any;
  handleActiveCategory?: any;
  isSelector?: boolean;
}

const PureReactCarousel = (props: PureReactCarouselProps) => {

  const [activeSlide, setActiveSlide] = useState();
  const [finalItems, setFinalItems] = useState<any[]>([]);
  const [hasSubcategories, setHasSubcategories] = useState<boolean>(false);

  useEffect(() => {
    // Filter items if carousel will be used as a selector:
    if (props.isSelector === true) {
      const subcategoriesArr: string[] = [];
      const subcatSamples: any[] = [];
      props.items.forEach((item: any) => {
        if (subcategoriesArr.indexOf(item.subcategory) === -1) {
          subcategoriesArr.push(item.subcategory);
          subcatSamples.push(item);
        }
      });
      if (subcatSamples.length > 0 && subcatSamples[0].subcategory !== "") setHasSubcategories(true);
      setFinalItems(subcatSamples);
    } else {
      setFinalItems(props.items);
    }
  }, [props.items, props.isSelector]);

  const toggleActiveSlide = (id: any) => {
    setActiveSlide(id);
    const activeItem = props.items.find((item: any) => item.id === id);
    if (props.handleActiveCategory) props.handleActiveCategory(activeItem.subcategory);
  };
  const buttonWidth: string = breakpointsHelper(window.innerWidth, [
    "6rem",
    "8rem",
    "10rem",
    "11rem",
    "12rem",
  ]);
  const buttonHeight: string = breakpointsHelper(window.innerWidth, [
    "1rem",
    "1.1rem",
    "1.4rem",
    "1.8rem",
    "1.8rem",
  ]);
  const fontSize: string = breakpointsHelper(window.innerWidth, [
    "0.8rem",
    "1rem",
    "1rem",
    "1rem",
    "1rem",
  ]);
  // TODO: avoid hardcoding colors
  const basicButtonStyle: any = {
    width: buttonWidth,
    height: buttonHeight,
    fontSize: fontSize,
    padding: 0,
    color: "white",
    border: "2px solid black",
    borderRadius: "32px"
  };
  const buttonStyle = {
    active: {
      ...basicButtonStyle,
      backgroundColor: "#cecece",
      color: "black",
      height: "2rem",
      width: "12rem",
      fontWeight: 500
    },
    inactive: {
      ...basicButtonStyle,
      backgroundColor: "#AA9E9E"
    }
  };
  const slideContents = (item: any) => {
    const maxEllipsisChars: number = breakpointsHelper(window.innerWidth, [
      20,
      13,
      13,
      14,
      14,
    ]);
    return (
      <>
        <img
          style={{ opacity: (activeSlide === item.id || props.compactMode) ? "1" : "0.3" }}
          onLoad={props.imageIsLoaded}
          src={item.image_link_1}
          alt={item.title}
          height="90%"
        />
        <HoverableButton caption={textEllipsis(item.subcategory, maxEllipsisChars)} style={(activeSlide === item.id || props.compactMode) ? buttonStyle.active : buttonStyle.inactive} onClick={props.isSelector && (() => {
          window.scrollTo(0, 500); // Scroll to show selected group
        })} />
      </>
    );
  };
  const carouselPadding: string = breakpointsHelper(window.innerWidth, ["2rem", "2rem", "0", "0", "0"]);
  return (
    (props.isSelector === true && finalItems.length <= 1 && !hasSubcategories) ? null :
      (
        <div
          className="container-fluid"
          style={{
            position: "relative",
            zIndex: 5,
            paddingLeft: carouselPadding,
            paddingRight: carouselPadding,
            display: props.visible === false ? "none" : "",
          }}
        >
          {props.isSelector && <u>CATEGORÍAS</u>}
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={133}
            totalSlides={finalItems.length}
            visibleSlides={breakpointsHelper(window.innerWidth, [1, 2, 2, 3, 3])}
            step={breakpointsHelper(window.innerWidth, [1, 2, 2, 3, 3])}
            infinite={true}
          >
            <Slider
              style={{ margin: "0" }}
            >
              {finalItems.map((item: any, key: any) => (
                <Slide className="carousel-slide" index={key} key={key}
                  onClick={() => {
                    toggleActiveSlide(item.id);
                    if (props.isSelector) window.scrollTo(0, 500); // Scroll to show selected group
                  }}
                >
                  {props.linkEnabled ? (
                    <Link
                      to={`/nuestros_productos/${item.category}/${item.title}_${item.id}_${item.sku}`}
                    >
                      {slideContents(item)}
                    </Link>
                  ) : (
                    slideContents(item)
                  )}
                </Slide>
              ))}
            </Slider>
            <div>
              <ButtonBack className="buttonBack">
                <img
                  src={CarouselArrow}
                  alt=""
                  style={{
                    height: "50px",
                  }} /* TODO: define size dynamically */
                />
              </ButtonBack>
            </div>
            <ButtonNext className="buttonNext">
              <img
                src={CarouselArrow}
                alt=""
                style={{ height: "50px" }} /* TODO: define size dynamically */
              />
            </ButtonNext>
          </CarouselProvider>
        </div>
      )
  );
};

export default PureReactCarousel;