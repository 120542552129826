import React from "react";
import PageUnderConstruction from "./PageUnderConstruction";

class SocialNetworks extends React.Component<
  {},
  { isLoading: boolean; error: any }
> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <PageUnderConstruction />;
  }
}

export default SocialNetworks;
