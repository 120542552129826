import React from "react";
import { StandardForm } from "./StandardForm";
import breakpointsHelper from "../utils/breakpointsHelper";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";

class Contact extends React.Component<
  {},
  {}
> {
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          paddingTop: breakpointsHelper(window.innerWidth, [
            "6rem",
            "6rem",
            "4.2rem",
            "4.2rem",
            "4.2rem",
          ]),
          paddingBottom: breakpointsHelper(window.innerWidth, [
            "2rem",
            "2rem",
            "4.2rem",
            "4.2rem",
            "4.2rem",
          ]),
        }}
      >
        <WhatsAppFloatingLogo />
        <h1
          style={{
            fontFamily: "Oswald",
            fontSize: breakpointsHelper(window.innerWidth, [
              "3rem",
              "3rem",
              "4.2rem",
              "4.2rem",
              "4.2rem",
            ]),
            letterSpacing: "3px",
          }}
        >
          ESCRIBINOS
        </h1>
        <h1
          style={{
            fontSize: breakpointsHelper(window.innerWidth, [
              "2rem",
              "2rem",
              "3rem",
              "3rem",
              "3rem",
            ]),
          }}
        >
        </h1>
        <StandardForm usesEmailTemplate={false} sendButtonInitialCaption="ENVIAR" formClassName="col-lg-4 col-sm-12" outerDivClassName="row justify-content-center" />
      </div>
    );
  }
}

export default Contact;
