import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "antd/dist/antd.css";
import Reaptcha from "reaptcha";
import { Form, Input, Button } from "antd";
import * as Constants from "../utils/constants";
import { CartContext } from "./CartContext";
import { emailTemplate } from "../utils/emailTemplate";

export const StandardForm = (props: {
  sendButtonInitialCaption: string;
  outerDivClassName: string;
  formClassName: string;
  usesEmailTemplate: boolean;
}) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cart, setCart] = useContext(CartContext);
  // const [isLoading, setIsLoading] = useState(false);
  // const [mailSent, setMailSent] = useState(null);
  // const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cuit, setCuit] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [transport, setTransport] = useState("");
  const [comments, setComments] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [emailStatusMessage, setEmailStatusMessage] = useState("");
  const [emailStatusMessageVisible, setEmailStatusMessageVisible] = useState(false);
  const [sendButtonColor, setSendButtonColor] = useState("");
  const [sendButtonCaption, setSendButtonCaption] = useState(props.sendButtonInitialCaption);
  const [emailBodyContents, setEmailBodyContents] = useState<string | null>(null);

  // _isMounted = false;

  useEffect(() => {
    // this._isMounted = true;
    // setIsLoading(true);
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }, []);

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // Creates email body with helper function:
  useEffect(() => {
    const emailBody: string = emailTemplate({ name: name, surname: surname, products: cart, comments: comments, phone: phone, email: email, cuit: cuit, city: city, address: address, transport: transport });
    if (props.usesEmailTemplate) setEmailBodyContents(emailBody); // Only sets email body contents if the email is using an html template (see emailTemplate.ts and send_email.php)
  }, [name, surname, cart, comments, phone, address, city, cuit, transport, props.usesEmailTemplate]);

  const onCaptchaVerify = (recaptchaResponse: any) => {
    setCaptchaVerified(true);
    setSubmitButtonEnabled(true);
  };

  const onFinish = async (values: any) => {
    setSubmitButtonEnabled(false); // Disable button immediately to avoid triggering the same email several times
    axios({
      method: "post",
      url: `${Constants.emailApiPath}`,
      headers: { "content-type": "application/json" },
      data: { email, name, surname, phone, address, city, cuit, transport, comments, emailBodyContents },
    })
      .then((result) => {
        // setMailSent(result.data.sent);
        setEmailStatusMessage("Te contactaremos a la brevedad.//Muchas gracias!");
        setEmailStatusMessageVisible(true);
        setSubmitButtonEnabled(false);
        setSendButtonCaption("ENVIADO!");
        setSendButtonColor("green");
      })
      .catch((error) => {
        // setError(error.message);
        setSubmitButtonEnabled(true); // re-enable button to allow retry in case of errors
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    setEmailStatusMessage(`Completa los campos requeridos`);
    setEmailStatusMessageVisible(true);
  };

  return (
    <div
      className="container-fluid"
    >
      <div className={props.outerDivClassName}>
        <Form
          className={props.formClassName}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            className="form-field"
            name="name"
            rules={[{ required: true, message: "Ingresa tu nombre" }]}
          >
            <Input
              placeholder="Nombre"
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="surname"
            rules={[{ required: true, message: "Ingresa tu apellido" }]}
          >
            <Input
              placeholder="Apellido"
              name="surname"
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="cuit"
            rules={[{ required: true, message: "Ingresa tu CUIT" }]}
          >
            <Input
              placeholder="CUIT"
              name="cuit"
              onChange={(e) => setCuit(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="address"
            rules={[{ required: true, message: "Ingresa tu dirección" }]}
          >
            <Input
              placeholder="Dirección"
              name="address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="city"
            rules={[{ required: true, message: "Ingresa tu localidad/ciudad" }]}
          >
            <Input
              placeholder="Localidad/Ciudad"
              name="city"
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="transport"
            rules={[{ required: true, message: "Ingresa tu transporte" }]}
          >
            <Input
              placeholder="Transporte"
              name="transport"
              onChange={(e) => setTransport(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="email"
            rules={[{ required: true, message: "Ingresa tu e-mail" }]}
          >
            <Input
              type="email"
              placeholder="E-mail"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="phone"
            rules={[{ required: true, message: "Ingresa tu teléfono" }]}
          >
            <Input
              type="phone"
              placeholder="Teléfono"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="form-field"
            name="comments"
            rules={[{ required: true, message: "Ingresa un mensaje" }]}
          >
            <Input.TextArea
              placeholder="Escribí tu mensaje"
              rows={7}
              name="comments"
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Item>
          <div className="custom-differential-wrapper">
            <Form.Item
              className="captcha-button"
              name="captcha"
              style={{ display: "inline-block" }}
            >
              <div className="text-center">
                <Reaptcha
                  sitekey={Constants.recaptchaPublicKey}
                  onVerify={onCaptchaVerify}
                />
              </div>
            </Form.Item>
          </div>
          <div className="custom-differential-wrapper">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                // size="large" // For some reason, changing this also changes button color. Review
                disabled={!submitButtonEnabled}
                style={{
                  backgroundColor: sendButtonColor,
                  fontWeight: "bold",
                }}
              >
                {sendButtonCaption}
              </Button>
            </Form.Item>
          </div>
          {emailStatusMessageVisible ? (
            <div className="row justify-content-center">
              <div
                className="col-8 col-8"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "9px",
                }}
              >
                <b>
                  {emailStatusMessage
                    .split("//")
                    .map((line: any, key: any) => (
                      <p
                        key={key}
                        style={{
                          margin: 0,
                        }}
                      >
                        {line}
                      </p>
                    ))}
                </b>
              </div>
            </div>
          ) : null}
        </Form>
      </div>
    </div>
  );
};