

export const HeaderWithSidelines = (props: any) => {
  return (
    <div className="headerWithSidelines">
      <span style={{ fontFamily: "Oswald", fontSize: props.fontSize }}>
        {props.headerText}
      </span>
    </div>
  );
};