import React, { useContext } from "react";
import HeaderLogo from "./HeaderLogo";
import * as Constants from "../utils/constants";
import { NavLink } from "react-router-dom";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { CartContext } from "./CartContext";
import { cartTotalQuantity } from "../utils/cartHelper";
import { BlankSideColumns } from "../components/BlankSideColumns";

const sections = Constants.sections;

// TODO: avoid hardcoding styles and dimensions!!
export const Header = () => {

  const openNav = () => {
    document.getElementById("navbarOverlay")!.style.height = "100%";
  };

  const closeNav = () => {
    document.getElementById("navbarOverlay")!.style.height = "0%";
  };

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cart, setCart] = useContext(CartContext);
  const header: any = (
    /* TODO: avoid hardcoding innerWidth value, define value in Constants file */
    <div
      className={`col ${window.innerWidth > 769 ? "" : ""
        }`}
    >
      {/* Columns with dynamic width for large screen */}
      <BlankSideColumns childComponent={
        <ul className="navbar-nav mx-auto flex-row">
          <HeaderLogo />
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            {sections.map((item, key) => (
              <li className={`nav-item`} key={key}>
                <NavLink
                  className="nav-link"
                  exact
                  to={`/${item.path}`}
                  style={{ color: "white" }}
                >
                  {item.visibleName}
                </NavLink>
              </li>
            ))}
          </div>
          <li id="header-cart-icon">
            <NavLink
              // className="nav-link"
              exact
              to={Constants.cartLink}
              style={{ color: "white" }}
            >
              <ShoppingCart style={{ fontSize: "1.8rem" }} />
              <span
                className="badge badge-warning"
                id="lblCartCount"
                style={cartTotalQuantity(cart) > 0 ? { background: Constants.cartBadgeColor } : { background: "transparent", width: "1.5rem" }}
              >
                {" "}
                {cartTotalQuantity(cart) > 0 ? cartTotalQuantity(cart) : ""}
                {" "}
              </span>
            </NavLink>
          </li>
        </ul>}>
      </BlankSideColumns>
    </div >
  );

  return (
    <nav
      className="navbar fixed-top navbar-dark navbar-expand-lg justify-content-md-center justify-content-start"
      style={{
        color: Constants.secondaryColor,
        backgroundColor: Constants.primaryColor,
        opacity: Constants.opacity,
        position:
          "relative" /* Repositioning from the natural layout to overlap with transparent header*/,
        width: "100%",
        top: Constants.topMessageHeight,
      }}
    >
      <div id="navbarOverlay" className="navbar-overlay">
        <button
          className="closebtn btn bg-transparent"
          style={{ color: "white" }}
          onClick={closeNav}
        >
          &times;
        </button>
        <div className="navbar-overlay-content">
          {sections.map((item, key) => (
            <div className="d-flex justify-content-start" key={key}>
              <li className={`nav-item`} key={key} onClick={closeNav}>
                <NavLink
                  className="nav-link"
                  exact
                  to={`/${item.path}`}
                  style={{ color: "white", paddingLeft: 0, paddingRight: 0 }}
                >
                  <b>{item.visibleName}</b>
                </NavLink>
              </li>
            </div>
          ))}
        </div>
      </div>
      <div
        className="container-fluid align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <button
          className="col-2 navbar-toggler"
          type="button"
          data-toggle="collapse"
          // data-target="#navbarSupportedContent" // Removed to suppress its original functionality
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={openNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {header}
      </div>
    </nav>
  );
};