import React from "react";
import * as Constants from "../utils/constants";
import MediaQuery from "react-responsive";

class HighlightsGrid extends React.Component<{ messages: any }> {
  render() {
    return (
      <div>
        <MediaQuery maxWidth={700}>
          <div
            className="container-fluid"
            style={{ backgroundColor: "#fef0f2", lineHeight: 1 }}
          >
            <div className="row" style={{ padding: "1rem" }}>
              <div className="col" style={{ paddingBottom: "9px" }}>
                <div
                  style={{
                    fontFamily: "Oswald",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "2rem",
                    color: "black",
                  }}
                >
                  {Constants.messagesForHomepageGrid}
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={701}>
          <div>
            {this.props.messages.map((message: any, key: any) => (
              <div
                key={key}
                style={{
                  fontFamily: "Oswald",
                  padding: "2.5rem",
                  width: "100%",
                  verticalAlign: "middle",
                  fontSize: "4rem",
                  backgroundColor: "#fef0f2",
                  color: "black",
                }}
              >
                {message}
              </div>
            ))}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default HighlightsGrid;
