import React, { useContext, useState } from "react";
import { Transition } from "react-transition-group";
import { CartContext } from "./CartContext";
import HoverableButton from "./HoverableButton";
import { useLocalStorage, deleteProductAndUpdateCart, changeProdQuantity } from "../utils/cartHelper";
import Delete from "@material-ui/icons/Delete";
import breakpointsHelper from "../utils/breakpointsHelper";

export const CartItem = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localStorageCart, setLocalStorageCart] = useLocalStorage("cart", "");
  // @ts-ignore
  const [cart, setCart] = useContext(CartContext);
  const [prodQuantity, setProdQuantity] = useState(props.item.quantity);
  // Handles fade out effect on cart item:
  const [fadeOutClass, setFadeOutClass] = useState(false);

  const fadeOutTimeInMillis: number = 500;

  const defaultStyle = {
    transition: `opacity ${fadeOutTimeInMillis}ms ease-in-out`,
    opacity: 1,
  };

  const transitionStyles: any = {
    entering: { opacity: 0 },
    entered: { opacity: 0 },
    exiting: { opacity: 1 },
    exited: { opacity: 1 },
  };

  const buttonWidth: string = breakpointsHelper(window.innerWidth, [
    "3rem",
    "3rem",
    "3rem",
    "3rem",
    "3rem",
  ]);
  const buttonHeight: string = breakpointsHelper(window.innerWidth, [
    "2rem",
    "2rem",
    "3rem",
    "3rem",
    "3rem",
  ]);
  const fontSize: string = breakpointsHelper(window.innerWidth, [
    "0.8rem",
    "1rem",
    "1.1rem",
    "1.1rem",
    "1.1rem",
  ]);

  const cartButtonStyle: any = {
    width: buttonWidth,
    height: buttonHeight,
    fontSize: fontSize
  };

  return (
    <Transition in={fadeOutClass} timeout={fadeOutTimeInMillis}>
      {state => (
        <div className="row"
          style={{
            color: props.isTotalAmount === true ? "white" : "black",
            backgroundColor: props.isTotalAmount === true ? "black" : "white",
            border: "1px solid darkgray",
            borderRadius: "10px",
            padding: "1.5rem",
            margin: "1rem",
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
          <div className="col-3">
            {props.isTotalAmount ? null
              :
              <img
                src={props.item.image_link_1}
                alt={props.item.image_link_1}
                height="90rem"
                style={{ boxShadow: "2px 2px 4px darkgray" }}
              />}
          </div>
          <div className="col-6">
            <div className="row justify-content-center" style={{ height: "50%" }}>
              {props.item.title.toUpperCase()}
            </div>
            {(props.isTotalAmount || window.innerWidth < 500) ? null
              :
              <div className="row justify-content-center">
                <div className="col">
                  <HoverableButton style={cartButtonStyle} caption="-" onClick={() => changeProdQuantity({ sign: "-", currentValue: prodQuantity, hookToSetVisibleValue: setProdQuantity, bulto: props.item.bulto, prod: props.item, cart: cart, hookToUpdateCart: setCart, hookToUpdateLocalStorage: setLocalStorageCart })} />
                </div>
                <div className="col text-center">
                  {prodQuantity}
                </div>
                <div className="col">
                  <HoverableButton style={cartButtonStyle} caption="+" onClick={() => changeProdQuantity({ sign: "+", currentValue: prodQuantity, hookToSetVisibleValue: setProdQuantity, bulto: props.item.bulto, prod: props.item, cart: cart, hookToUpdateCart: setCart, hookToUpdateLocalStorage: setLocalStorageCart })} />
                </div>
              </div>
            }
          </div>
          {props.isTotalAmount ? null
            :
            <div className="col-3">
              <div
                className="row justify-content-center"
                onClick={() => {
                  setFadeOutClass(true);
                  const delay = setInterval(() => {
                    deleteProductAndUpdateCart(cart, props.item, setCart, setLocalStorageCart);
                    clearInterval(delay);
                  }, fadeOutTimeInMillis);
                }}>
                <Delete style={{ fontSize: "3rem", cursor: "pointer" }} />
              </div>
              <div className="row justify-content-center">
                {prodQuantity}{prodQuantity > 1 ? " Unidades" : " Unidad"}
              </div>
            </div>
          }
        </div>
      )}
    </Transition>
  );
};