// TODO: doesn't change dynamically when resizing, only when reloading. Consider using ResponsiveContainerTest module with react hooks if necessary
// TODO: consider expanding functionality for more breakpoints, even passing object with widths as keys instead of array of sorted elements

const breakpointsHelper = (windowInnerWidth: number, sortedElements: any) => {
  if (windowInnerWidth < 600) {
    return sortedElements[0];
  } else if (windowInnerWidth < 768) {
    return sortedElements[1];
  } else if (windowInnerWidth < 992) {
    return sortedElements[2];
  } else if (windowInnerWidth < 1200) {
    return sortedElements[3];
  } else {
    return sortedElements[4];
  }
};

export default breakpointsHelper;
