import React from "react";
import { NavLink } from "react-router-dom";
import * as Constants from "../utils/constants";
import Modal from "react-bootstrap/Modal";
import HoverableButton from "./HoverableButton";

export const BootstrapModal = (props: any) => {

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          closeButton
          style={{
            border: "none"
          }}
          className="text-center">
          <Modal.Title>
            ¡AGREGADO AL CARRITO CON ÉXITO!
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            border: "none"
          }}>
          <div className="row">
            <div className="col">
              <NavLink
                className="nav-link"
                exact
                to={Constants.cartLink}
                style={{ padding: "0 0 0.5rem 0" }}
              >
                <HoverableButton caption="VER CARRITO"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "32px"
                  }} />
              </NavLink>
              <HoverableButton
                caption="SEGUIR COMPRANDO" onClick={props.handleClose}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "32px"
                }} />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};