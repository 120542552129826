import React from "react";
import { Button } from "antd";

interface HoverableButtonProps {
  caption: string;
  style?: any;
  onClick?: any;
  disabled?: boolean
}

interface HoverableButtonState {
  hover: boolean;
  active: boolean;
  focus: boolean;
}

class HoverableButton extends React.Component<
  HoverableButtonProps,
  HoverableButtonState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hover: false,
      active: false,
      focus: false,
    };
  }

  // Helper functions to toggle button states and apply inline css styles accordingly:
  getInitialState = () => {
    return { hover: false, active: false, focus: false };
  }
  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  }
  toggleActive = () => {
    this.setState({ active: !this.state.active });
  }
  toggleFocus = () => {
    this.setState({ focus: !this.state.focus });
  }

  render() {
    const standardStyleClassName: string = " hoverable-button";
    return (
      <Button
        disabled={this.props.disabled ? this.props.disabled : false}
        block
        className={`container${standardStyleClassName}`}
        style={this.props.style ? this.props.style : null}
        onMouseOver={this.toggleHover}
        onMouseOut={this.toggleHover}
        onMouseUp={this.toggleActive}
        onMouseDown={this.toggleActive}
        onFocus={this.toggleFocus}
        onClick={this.props.onClick ? this.props.onClick : undefined}
      >
        {this.props.caption.toUpperCase()}
      </Button>
    );
  }
}

export default HoverableButton;
