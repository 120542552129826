import React, { useContext, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { Link } from "react-router-dom";
import CarouselArrow from "../images/flecha.svg";
import "antd/dist/antd.css";
import breakpointsHelper from "../utils/breakpointsHelper";
import WhatsAppFloatingLogo from "./WhatsAppFloatingLogo";
import { CartContext } from "./CartContext";
import { useEffect } from "react";
import { cloneItem, useLocalStorage, changeProdQuantity, getProductIndex } from "../utils/cartHelper";
import HoverableButton from "./HoverableButton";
import { BootstrapModal } from "./BootstrapModal";

const SingleProduct = (props: any) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [mailSent, setMailSent] = useState(null);
  // const [error, setError] = useState(null);
  // const [name, setName] = useState("");
  // const [surname, setSurname] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  // const [captchaPassed, setCaptchaPassed] = useState(false);
  const [prodQuantity, setProdQuantity] = useState(props.itemProps.bulto !== "" ? 1 * parseInt(props.itemProps.bulto) : 1);

  // @ts-ignore
  const [cart, setCart] = useContext(CartContext);
  const [modalVisibility, setModalVisibility] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localStorageCart, setLocalStorageCart] = useLocalStorage("cart", "");

  // TODO: move to cartHelper.ts, passing hooks as params like cartItem.tsx
  const addToCart = (product: any) => {
    const copiedProduct = cloneItem(product); // Create copy to prevent propagating quantity changes to same object
    copiedProduct.quantity = prodQuantity;
    setModalVisibility(true);
    // Checks if product was already added to cart. In that case, just sum the existing amount of items in cart for that product. Otherwise, add product:
    const productIndexInCart = getProductIndex(cart, copiedProduct, "id");
    if (productIndexInCart !== -1) {
      const copiedCart = cloneItem(cart);
      copiedCart[productIndexInCart].quantity += prodQuantity;
      setLocalStorageCart(copiedCart);
      setCart(copiedCart);
    } else {
      setLocalStorageCart([...cart, copiedProduct]);
      setCart([...cart, copiedProduct]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to top before loading, otherwise it may retain the position from a previous view
  }, []);

  // Array with all possible images per product:
  const imagesArray = [
    props.itemProps.image_link_1,
    props.itemProps.image_link_2,
    props.itemProps.image_link_3,
    props.itemProps.image_link_4,
  ];

  // Helper function to return amount of valid (non-empty) images per product:
  const amountOfSlides = (imagesArray: any) => {
    let amountOfSlides = 0;
    imagesArray.forEach((imageLink: string) => {
      if (imageLink !== "") {
        amountOfSlides += 1;
      }
    });
    return amountOfSlides;
  };

  const handleModalClose = () => {
    setModalVisibility(false);
  };

  return (
    <div>
      <BootstrapModal show={modalVisibility} handleClose={handleModalClose} />
      <WhatsAppFloatingLogo />
      <div
        className="container-fluid"
        style={{
          padding: "3rem",
        }}
      >
        <div
          className="row"
          style={{
            marginBottom: breakpointsHelper(window.innerWidth, [
              "0",
              "0",
              "2.5rem",
              "2.5rem",
              "2.5rem",
            ]),
          }}
        >
          {/* Breadcrumbs: */}
          <div
            className="col-sm-12 col-lg-6"
            style={{ textAlign: "left", letterSpacing: "3px" }}
          >
            <span>
              <Link
                to="/nuestros_productos"
                style={{
                  color: "black",
                  paddingLeft: 0,
                  paddingBottom: "0.8rem",
                }}
              >
                Nuestros Productos
              </Link>
            </span>
            <span> / </span>
            <span>
              <Link
                to={`/nuestros_productos/${props.itemProps.category}`}
                style={{
                  color: "black",
                  paddingLeft: 0,
                  paddingBottom: "0.8rem",
                }}
              >
                {props.itemProps.category}
              </Link>
            </span>
            <span> / </span>
            <span>
              <b>{props.itemProps.title}</b>
            </span>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-12 col-lg-4 offset-lg-2"
            style={{
              marginRight: "2.5%",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={133}
              totalSlides={amountOfSlides(imagesArray)}
              visibleSlides={1}
              step={1}
              infinite={true}
            >
              <Slider
                style={{
                  margin: breakpointsHelper(window.innerWidth, [
                    "0",
                    "0",
                    "3rem",
                    "3rem",
                    "3rem",
                  ]),
                }}
              >
                {imagesArray.map((image: any, key: any) => {
                  if (image !== "") {
                    // Creating slide for non-empty images only
                    return (
                      <Slide index={key} key={key}>
                        <img
                          src={image}
                          alt={image}
                          height="100%"
                          width="100%"
                        />
                      </Slide>
                    );
                  } else {
                    return null;
                  }
                })}
              </Slider>
              <div>
                <ButtonBack className="buttonBack">
                  <img
                    src={CarouselArrow}
                    alt=""
                    style={{
                      height: "50px",
                    }} /* TODO: define size dynamically */
                  />
                </ButtonBack>
              </div>
              <ButtonNext className="buttonNext">
                <img
                  src={CarouselArrow}
                  alt=""
                  style={{
                    height: "50px",
                  }} /* TODO: define size dynamically */
                />
              </ButtonNext>
            </CarouselProvider>
          </div>
          <div
            className="col-sm-12 col-lg-4 align-self-center"
            style={{
              textAlign: "left",
              color: "black",
              marginLeft: "2.5%",
            }}
          >
            <h3
              style={{
                fontWeight: "bold",
                textAlign: "left",
                color: "grey",
                fontSize: breakpointsHelper(window.innerWidth, [
                  "1.2rem",
                  "1.2rem",
                  "1.75rem",
                  "1.75rem",
                  "1.75rem",
                ]),
              }}
            >
              {props.itemProps.category.toUpperCase()}
            </h3>
            <h1
              style={{
                fontFamily: "Oswald",
                fontSize: breakpointsHelper(window.innerWidth, [
                  "3.2rem",
                  "3.2rem",
                  "3.5rem",
                  "3.5rem",
                  "3.5rem",
                ]),
                letterSpacing: "3px",
                color: "black",
                lineHeight: breakpointsHelper(window.innerWidth, [
                  "1",
                  "1",
                  "1.2",
                  "1.2",
                  "1.2",
                ]),
              }}
            >
              {props.itemProps.title.toUpperCase()}
            </h1>
            {props.itemProps.description.split("//").map((
              line: any,
              key: any // Mapping description items separated by "//" in spreadsheet
            ) => (
              <p
                key={key}
                style={{
                  fontSize: breakpointsHelper(window.innerWidth, [
                    "1rem",
                    "1rem",
                    "1.2rem",
                    "1.2rem",
                    "1.2rem",
                  ]),
                }}
              >
                {line}
              </p>
            ))}
            <hr />
            <div
              className="row align-items-center">
              <div className="col-6">
                <p
                  style={{
                    margin: 0,
                    fontSize: breakpointsHelper(window.innerWidth, [
                      "1rem",
                      "1rem",
                      "1.2rem",
                      "1.2rem",
                      "1.2rem",
                    ]),
                  }}
                >
                  CANTIDAD
                </p>
              </div>
              <div className="col-2">
                <HoverableButton caption="-"
                  style={{
                    borderRadius: "32px"
                  }}
                  onClick={() => changeProdQuantity({ sign: "-", currentValue: prodQuantity, bulto: props.itemProps.bulto, hookToSetVisibleValue: setProdQuantity })} />
              </div>
              <div className="col-2 text-center">
                {prodQuantity}
              </div>
              <div className="col-2">
                <HoverableButton caption="+"
                  style={{
                    borderRadius: "32px"
                  }}
                  onClick={() => changeProdQuantity({ sign: "+", currentValue: prodQuantity, bulto: props.itemProps.bulto, hookToSetVisibleValue: setProdQuantity })} />
              </div>
            </div>
            <br />
            <br />
            <HoverableButton
              disabled={props.itemProps.stock === "NO" ? true : false}
              caption={props.itemProps.stock === "NO" ? "Fuera de Stock" : "Añadir al Carrito"}
              onClick={() => addToCart(props.itemProps)}
              style={{
                backgroundColor: props.itemProps.stock === "NO" ? "grey" : "black",
                color: "white",
                borderRadius: "32px"
              }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
